// External dependencies
import { Div } from 'atomize';
import React, { useState } from 'react';

// Local dependencies
import { DropdownElement } from '../common/types';
import Search from '../search/Search';
import Spinner from '../spinner/Spinner';
import AsyncSelectItem from './AsyncSelectItem';

interface AsyncSelectProps {
    items: DropdownElement[];
    loading: boolean;
    onSelectItem: (item: DropdownElement) => void;
    onSearch: (value: string) => void;
    onClear: () => void;
    searchString: string;
    searchPlaceholder: string;
}

export default function AsyncSelect({
    items,
    loading,
    onSelectItem,
    onSearch,
    onClear,
    searchString,
    searchPlaceholder,
}: AsyncSelectProps) {
    const [dropdownOpen, setDropdownOpen] = useState(true)

    function searchItems(value: string) {
        setDropdownOpen(true)
        onSearch(value)
    }

    return (
        <Div>
            <Div minW="100%">
                <Div pos='relative'>
                    <Search
                        minH="50px"
                        maxWidth="100%"
                        placeholder={searchPlaceholder}
                        value={searchString}
                        onChange={searchItems}
                        onClear={onClear}
                    />
                    <Div maxH='150px' style={{ overflowY: 'scroll' }} pos='absolute' top='100%' right='0' left='0' zIndex='100' bg='white'>
                        <Div bg="#fffff" rounded="12px" borderColor="black" style={{ boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.1)' }}>
                            {searchString && loading ? (
                                <Div
                                    overflow="hidden"
                                    bg="#fffff"
                                    hoverBg="rgb(248, 248, 252)"
                                    d="flex"
                                    style={{ gap: '10px' }}
                                    minH="60px"
                                    align="center"
                                    p="10px"
                                    cursor="pointer"
                                    pos="relative"
                                >
                                    <Spinner />
                                </Div>
                            ) : (
                                searchString && dropdownOpen && <AsyncSelectItem setDropdownOpen={setDropdownOpen} items={items} onSelectItem={onSelectItem} />
                            )}
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Div>
    );
}
