// External dependencies
import { Icon, Text } from 'atomize';
import React from 'react';

// Local dependencies
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import DateTime from '../common/DateTime';
import { ConsigneeGoodSpecifications } from './types';

export const fields = [
  {
    field: (goodsSpecifications: ConsigneeGoodSpecifications) => (
      <DateTime date={goodsSpecifications.createdDate} />
    ),
    title: <FormattedMessage id="createdDate" />,
  },
  {
    field: (goodsSpecifications: ConsigneeGoodSpecifications) => (
      <a
        href={goodsSpecifications.exportFile?.url}
        download
      >
        Скачать
      </a>
    ),
    title: <FormattedMessage id="groupedFile" />,
  },
  {
    field: (goodsSpecifications: ConsigneeGoodSpecifications) =>
      goodsSpecifications.exportFile?.linesCount,
    title: <FormattedMessage id="goupedFileLinesCount" />,
  },
  {
    field: (goodsSpecifications: ConsigneeGoodSpecifications) => (
      <a
        href={goodsSpecifications.sourceFile?.url}
        download
      >
        Скачать
      </a>
    ),
    title: <FormattedMessage id="sourceFile" />,
  },
  {
    field: (goodsSpecifications: ConsigneeGoodSpecifications) =>
      goodsSpecifications.sourceFile?.linesCount,
    title: <FormattedMessage id="sourceFileLinesCount" />,
  },
  {
    field: (goodsSpecifications: ConsigneeGoodSpecifications) =>
      goodsSpecifications?.consigneeProfile?.name,
    title: <FormattedMessage id="consigneeName" />,
  },
];

export function getTopBar(loading: boolean, total: number) {
  return (
    <Text
      textColor="table_cell_text_color"
      textSize={{ xs: 'mobileTitle', md: 'title' }}
      textWeight="700"
    >
      <FormattedMessage id="GoodsSpecifications" />
      {loading ? (
        <Icon m="0 .62rem" name="Loading3" size="20px" />
      ) : (
        ` (${total})`
      )}
    </Text>
  );
}
