// Local dependencies
import { Col, Row } from 'atomize';
import React, { useState } from 'react';

// Local dependencies
import { useDispatch, useSelector } from 'react-redux';
import formatMessage from '../../intl/formatMessage';
import { RootState } from '../../redux/store';
import PrimaryButton from '../buttons/PrimaryButton';
import Search from '../search/Search';
import CreateConsigneeGoodSpecifications from './createConsigneeGoodsSpecifications/CreateConsigneeGoodSpecifications';
import { listConsigneeGoodsSpecificationsRequest } from './listConsigneeGoodsSpecifications/redux/actions';

export default function GoodsSpecificationsControlBar() {
  const findConsigneeGoodsSpecifications = 'finByConsigneeName';
  const dispatch = useDispatch();
  const { searchString } = useSelector(
    (state: RootState) => state.consigneeGoodsSpecifications,
  );
  const [openForm, setOpenForm] = useState(false)

  function onSearch(e) {
    dispatch(listConsigneeGoodsSpecificationsRequest(e.target.value));
  }

  function resetSearchField() {
    dispatch(listConsigneeGoodsSpecificationsRequest(''));
  }

  function handleAddGoodsSpecification() {
    setOpenForm(true)
  }

  return (

    <Row
      m={{ y: '.5rem', x: '-.5rem' }}
      justify="space-between"
      align={{ xs: 'flex-start', md: 'space-between' }}
    >
      <Col size={{ xs: '12', md: '6' }} style={{ marginBottom: '1rem' }}>
        <Search
          onChange={onSearch}
          onClear={resetSearchField}
          placeholder={formatMessage(findConsigneeGoodsSpecifications)}
          value={searchString}
        />
      </Col>
      <Col
        size={{ xs: '12', md: '6' }}
        d={{ xs: 'block', md: 'flex' }}
        justify={{ md: 'flex-end' }}
        style={{ marginBottom: '1rem' }}
      >
        <PrimaryButton onClick={handleAddGoodsSpecification} icon="Plus">
          {formatMessage('createGoodSpecification')}
        </PrimaryButton>
      </Col>
      {
        openForm && <CreateConsigneeGoodSpecifications setFormOpen={setOpenForm} />
      }
    </Row>
  );
}


