// External dependencies
import { Div, Text } from 'atomize';
import React from 'react';

// Local dependencies
import { DropdownElement } from '../common/types';

export interface AsyncSelectItemProps {
    items: DropdownElement[];
    onSelectItem: (item: DropdownElement) => void;
    setDropdownOpen: (value: boolean) => void
}

export default function AsyncSelectItem({ items, onSelectItem, setDropdownOpen }: AsyncSelectItemProps): React.ReactElement {
    function selectItem(item: DropdownElement) {
        onSelectItem(item)
        setDropdownOpen(false)
    }

    return (
        <Div maxH='500px' overflow='auto'>
            {items?.map((item: DropdownElement) => {
                return (
                    <Div
                        onClick={() => selectItem(item)}
                        bg="#fffff"
                        hoverBg="rgb(248, 248, 252)"
                        d="flex"
                        style={{ gap: '10px' }}
                        minH="50px"
                        align="center"
                        p="10px"
                        cursor="pointer"
                    >
                        {
                            item?.image && <img height={45} width={45} src={item.image} alt={item.title} />
                        }

                        <Div>
                            <Text m={{ b: '8px' }} tag="p">
                                {item.title}
                            </Text>
                            <Text textColor="text_silver" tag="p">
                                {item.subTitle}
                            </Text>
                        </Div>
                    </Div>
                );
            })}
        </Div>
    );
}
