// External dependencies
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Local dependencies
import formatMessage from '../../../intl/formatMessage'
import { RootState } from '../../../redux/store'
import { listConsigneeProfiles } from '../../consignee-profiles/redux/actions'
import AsyncSelect from '../../custom-select/NewAsyncSearch'
import { createConsigneeGoodsSpecFields } from './redux/actions'

export default React.memo(function SelectConsigneeProfile() {
    const { consigneeProfiles, loading, searchString } = useSelector((state: RootState) => state.consigneeProfiles)
    const { consigneeGoodsSpecification } = useSelector((state: RootState) => state.consigneeGoodsSpecification)
    const dispatch = useDispatch()


    function onSearch(e) {
        dispatch(createConsigneeGoodsSpecFields({ consigneeProfile: { name: e.target.value } }))
        dispatch(listConsigneeProfiles(e.target.value))
    }

    function onClear() {
        dispatch(createConsigneeGoodsSpecFields({ consigneeProfile: { name: "" } }))
        dispatch(listConsigneeProfiles())
    }

    function onSelectService(data: any) {
        dispatch(createConsigneeGoodsSpecFields({ consigneeProfile: { id: data.id, name: data.title } }))
    }

    return (
        <div>
            <AsyncSelect
                items={consigneeProfiles?.map((consigneeProfile) => ({
                    id: consigneeProfile.id,
                    title: consigneeProfile.name
                }))}
                loading={loading}
                onSearch={onSearch}
                searchString={consigneeGoodsSpecification?.consigneeProfile?.name}
                searchPlaceholder={formatMessage("findConsigneeProfile")}
                onClear={onClear}
                onSelectItem={onSelectService}
            />
        </div>
    )
})
