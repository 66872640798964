// External dependencies
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Local dependencies
import { RootState } from '../../../redux/store';
import { getUserRole } from '../../common/helpers';
import { UserRoles } from '../../common/roles';
import Table from '../../tables/Table';
import GoodsSpecificationsControlBar from '../GoodsSpecificationsControlBar';
import { fields, getTopBar } from '../helper';
import { ConsigneeGoodSpecifications } from '../types';
import { listConsigneeGoodsSpecificationsRequest } from './redux/actions';

export default function ListConsigneeGoodsSpecifications() {
  const { currentUser } = useSelector((state: RootState) => state.login);
  const {
    consigneeGoodsSpecifications,
    loading,
    total,
    searchString,
    currentPage,
  } = useSelector((state: RootState) => state.consigneeGoodsSpecifications);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listConsigneeGoodsSpecificationsRequest());
  }, []);

  function onPageChange(page) {
    dispatch(listConsigneeGoodsSpecificationsRequest(searchString, page));
  }

  return (
    <Table
      currentPage={currentPage}
      columns={fields}
      controlBar={getControlBar()}
      className="table"
      emptyMessage={<FormattedMessage id="noGoodsSpecifications" />}
      items={consigneeGoodsSpecifications?.map(
        (consigneeGoodsSpecifications: ConsigneeGoodSpecifications) => ({
          ...consigneeGoodsSpecifications,
        }),
      )}
      loading={loading}
      onPageChange={onPageChange}
      searchString={searchString}
      stickyCol={1}
      topBar={getTopBar(loading, total)}
      totalItemsCount={total}
    />

  );

  function getControlBar() {
    const userRole = getUserRole(currentUser);

    switch (userRole) {
      case UserRoles.BTS_ADMIN:
        return <GoodsSpecificationsControlBar />;
      case UserRoles.BTS_CUSTOMS_REPRESENTATIVE:
        return <GoodsSpecificationsControlBar />;
      default:
        throw new Error(`Unrecognized Cognito user group role "${userRole}".`);
    }
  }
}
