// External dependencies
import { encode } from 'base64-arraybuffer';

export function convertImageToBase64(file): Promise<string> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);

    fileReader.onloadend = function () {
      resolve(fileReader.result);
    };

    fileReader.onerror = function () {
      reject(fileReader.result);
    };
  });
}

export function convertFileToBase64(file): Promise<string> {
  return new Promise((resolve) => {
    const fileReader = new FileReader();

    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (e) => {
      const data = e?.target?.result;

      resolve(encode(data as ArrayBuffer));
    };
  });
}
