// External dependencies
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Local dependencies
import formatMessage from '../../../intl/formatMessage'
import { RootState } from '../../../redux/store'
import { listCountriesRequest } from '../../countries/redux/actions'
import AsyncSelect from '../../custom-select/NewAsyncSearch'
import { createConsigneeGoodsSpecFields } from './redux/actions'

export default React.memo(function SelectCurrency() {
    const { countries, loading, searchString } = useSelector((state: RootState) => state.countries)
    const { consigneeGoodsSpecification } = useSelector((state: RootState) => state.consigneeGoodsSpecification)
    const dispatch = useDispatch()

    function onSearch(e) {
        dispatch(createConsigneeGoodsSpecFields({ currency: { id: e.target.value } }))
        dispatch(listCountriesRequest({}, e.target.value))
    }

    function onClear() {
        dispatch(createConsigneeGoodsSpecFields({ currency: { id: '' } }))
        dispatch(listCountriesRequest())
    }

    function onSelectService(data: any) {
        dispatch(createConsigneeGoodsSpecFields({ currency: { id: data.id } }))
    }

    return (
        <div>
            <AsyncSelect
                items={countries?.map((countries) => ({
                    id: countries?.id,
                    title: countries?.id
                }))}
                loading={loading}
                onSearch={onSearch}
                searchString={consigneeGoodsSpecification?.currency?.id}
                searchPlaceholder={formatMessage("findCurrency")}
                onClear={onClear}
                onSelectItem={onSelectService}
            />
        </div>
    )
})
