// External dependencies
import { Div } from 'atomize'
import React from 'react'

// Local dependencies
import { FormattedMessage } from 'gatsby-plugin-react-intl'
import { useDispatch } from 'react-redux'
import formatMessage from '../../../intl/formatMessage'
import ErrorPopup from '../../popup/ErrorPopup'
import SuccessPopup from '../../popup/SuccessPopup'
import { resetCreateConsigneeGoodsSpec } from './redux/actions'

interface SuccessPopupProps {
    isSuccess: boolean,
    error: null | Error
    children: any
}

export default function Boundaries({ isSuccess, children, error }: SuccessPopupProps) {
    const dispatch = useDispatch()
    console.log(error)

    function reset() {
        dispatch(resetCreateConsigneeGoodsSpec())
    }

    if (error) {
        const errorMessage =
            error &&
            (error?.code ? <FormattedMessage id={error?.code} /> : error.message);

        return <ErrorPopup
            onSubmit={reset}
            submitText={<FormattedMessage id="close" />}
            title={<FormattedMessage id="errorOccurred" />}
        >
            {errorMessage}
        </ErrorPopup>
    }

    if (!isSuccess) {
        return children
    }
    
    return (
        <SuccessPopup submitText='close' onSubmit={reset} title={formatMessage("operationSucceeded")}>
            <Div>{formatMessage('addedConsigneeGoodsSpecification')}</Div>
        </SuccessPopup>
    )
}
