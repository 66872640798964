// External dependencies
import { Col, Div, Label, Row } from 'atomize';
import React, { useState } from 'react';

// Local dependencies
import { useDispatch, useSelector } from 'react-redux';
import formatMessage from '../../../intl/formatMessage';
import { RootState } from '../../../redux/store';
import CancelButton from '../../buttons/CancelButton';
import DangerButton from '../../buttons/DangerButton';
import { convertFileToBase64 } from '../../common/convertImageToBase64';
import { defaultInputStyle, rowStyle } from '../../form/FormStyles';
import Popup from '../../popup/Popup';
import PopupActions from '../../popup/PopupActions';
import PopupContent from '../../popup/PopupContent';
import Boundaries from './Boundaries';
import SelectConsigneeProfile from './SelectConsigneeProfile';
import SelectCurrency from './SelectCurrency';
import { createConsigneeGoodsSpecFields, createConsigneeGoodsSpecRequest, resetCreateConsigneeGoodsSpec } from './redux/actions';

interface CreateConsigneeGoodSpecificationsFormProps {
  setFormOpen: (value: boolean) => void
}

export default function CreateConsigneeGoodSpecifications({ setFormOpen }: CreateConsigneeGoodSpecificationsFormProps) {
  const { loading, isSuccess, error } = useSelector((state: RootState) => state.consigneeGoodsSpecification)
  const customSelects = [
    <SelectConsigneeProfile />, <SelectCurrency />
  ]

  const borderColor = 'search_bg_color';
  const dispatch = useDispatch()

  function cancelSubmit() {
    setFormOpen(false)
    dispatch(resetCreateConsigneeGoodsSpec())
  }

  function onSubmit() {
    dispatch(createConsigneeGoodsSpecRequest())
  }

  const [filePicked, setFilePicked] = useState(false);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const base64 = await convertFileToBase64(file)

      setFilePicked(true);
      dispatch(createConsigneeGoodsSpecFields({ file: base64 }))

    } else {
      setFilePicked(false);
    }
  };


  return (
    <Boundaries isSuccess={isSuccess} error={error}>
      <Popup title={formatMessage("createConsigneeGoodsSpecification")}>
        <Div overflow='hidden'>
          <PopupContent>
            <Row>
              {
                customSelects?.map((item, index) =>
                  <Row key={index}   {...rowStyle}
                    align="baseline"
                    border={{ b: '1px solid' }}
                    p={{ b: '1rem' }}
                    borderColor={borderColor}>
                    <Col>
                      {item}
                    </Col>
                  </Row>
                )
              }
              <Row    {...rowStyle}
                align="baseline"
                border={{ b: '1px solid' }}
                p={{ b: '1rem' }}
                borderColor={borderColor}>
                <Col>
                  <Label d='flex' align='center' border='1px solid' {...defaultInputStyle} bg={filePicked ? 'success_color' : "input_bg"} w='330px' h='40px'>
                    {filePicked ? formatMessage("filePicked") : formatMessage("pickFile")}
                    <input onChange={handleFileChange} style={{ display: "none" }} type="file" />
                  </Label>
                </Col>
              </Row>
            </Row>
          </PopupContent>
          <PopupActions>
            <CancelButton textColor="text_color" onClick={cancelSubmit}>
              {formatMessage("cancel")}
            </CancelButton>
            <DangerButton loading={loading} onClick={onSubmit}> {formatMessage("add")}</DangerButton>
          </PopupActions>
        </Div>
      </Popup>
    </Boundaries>
  );
}




